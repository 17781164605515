<template>
  <a-modal
    :visible="visibleInvitationModal"
    @cancel="closevisibleInvitationModal"
    :footer="null"
    wrapClassName="invite-to-saved-folder"
  >
    <div class="invite-saved-folder">
      <h2 class="invite-saved-folder__title">
        Invite colleagues
      </h2>
      <tagify-contacts 
      placeholder="Share with your network" 
      :options="testing" 
      @fetchSelection="fetchedSelection"
      :isSubmitted="isSubmitted" 
      :id="this.folderId">
      </tagify-contacts>
      <p v-if="selectBoxErr" class="invite-saved-folder__error">{{ selectBoxErr }}</p>
      <a-button
        :loading="loading"
        @click="inviteOnFolder()"
        class="invite-saved-folder__btn"
        >Send</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import TagifyContacts from "../PdbComponents/TagifyContacts.vue";

export default {
  props: {
    visibleModal: Boolean,
    folderId: String
  },
  watch: {
    visibleModal() {
      this.visibleInvitationModal = this.visibleModal;
    },
  },
  data() {
    return {
      visibleInvitationModal: false,
      message: {},
      isSubmitted: false,
      showMessageModal: false,
      loading: false,
      selectBoxErr: "",
      selectedConnections: [],
      friends: [],
    };
  },
  components: {
    TagifyContacts,
  },
  computed: {
    testing() {
      const value = this.$store.getters["profile/getFriends"];
      value.map((val) => {
        if (val.profile) {
          let userList = {
            label: val.profile.full_name,
            value: val.id,
            key: val.email,
            icon: val.profile.profile_picture,
          };
          this.friends.push(userList);
          return userList;
        }
      });
      return this.friends;
    },
    isSubmit() {
      return this.isSubmitted;
    },
  },
  emits: ["closeModal"],
  methods: {
    closevisibleInvitationModal() {
      this.loading = false;
      this.selectBoxErr = "";
      this.$emit("closeModal");
    },
    fetchedSelection(val) {
      this.selectedConnections = val;
    },
    async inviteOnFolder() {
      if (this.selectedConnections.length === 0) {
        this.selectBoxErr = "This field can not be empty";
        return true;
      }
      try {
        this.loading = true;
        let connectionList = this.selectedConnections.map((val) => val.value );
        const payload = {
          connection_list: connectionList,
          link: "https://www.glocal.com/",
          id: this.folderId
        }
        const response = await this.$store.dispatch(
          "article/inviteToOthersOnFolders",
          payload
        );
        if (response.status == 200) {
          this.showMessageModal = true;
          this.$emit("closeModal", false , this.showMessageModal , 'success');
          this.loading = false;
          this.selectedConnections = [];
          this.selectBoxErr = "";
          this.isSubmitted=await true
          this.isSubmitted=await false
        }
      } catch (err) {
        const error = err.response.data[0];
        this.loading = false;
        // this.selectBoxErr = error;
        this.showMessageModal = true;
        this.$emit("closeModal", false , this.showMessageModal , error);
      }
    },
  },
};
</script>

<style lang="scss">
.invite-to-saved-folder {
  .ant-modal {
    width: 50rem !important;
    top: 18rem;
    .ant-modal-content {
      padding: 2.5rem;
      box-shadow: 0px 0px 94px #00000047;
      background: #f6f8ff;
      border-radius: 0;
      .ant-modal-close {
        margin: 3rem;
        .ant-modal-close-x {
          width: fit-content;
          height: fit-content;
          line-height: 0;
        }
      }
      .ant-modal-header {
        display: none;
      }
      .ant-modal-body {
        .invite-saved-folder {
          &__title {
            font-size: 2.4rem;
            font-family: $font-secondary-bold;
            line-height: normal;
            margin-bottom: 2.5rem;
          }
          &__error {
            color: red;
            font-size: 1.5rem;
            font-family: $font-secondary;
            margin: 0.5rem 0 0;
          }
          &__btn {
            font-size: 1.5rem;
            font-family: $font-primary-medium;
            width: 10rem;
            margin-top: 4rem;
            margin-left: 78%;
            height: 4rem;
            border: none;
            background-color: $color-primary;
            color: $color-white;
            padding: 0;
            span {
              line-height: normal;
            }
          }
          &__form {
            input {
              color: $color-black;
              font-size: 1.6rem;
              font-family: $font-secondary;
              border: 1px solid hsla(0, 0%, 43.9%, 0.2);
              width: 100%;
              padding: 0.6rem 1.2rem;
              outline: none;
              border-radius: 0;
              background-color: #f6f8ff;
              &::placeholder {
                opacity: 0.4;
              }
            }
          }
        }
      }
    }
  }
}
</style>
