<template>
  <div class="shared-articles-collection">
    <div
      v-if="impactSharedArticles === 0 && !searchingImpactSharedArticle"
      class="shared-articles-collection__message-results-not-found"
    >
      <p>No Shared Highlighted Current Events Found</p>
    </div>
    <div v-else class="shared-articles-collection__article-wrapper">
      <a-row :gutter="[18, 18]" v-if="searchingImpactSharedArticle">
        <a-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          v-for="index in 6"
          :key="index"
        >
          <a-skeleton
            class="impact-article-card-skeleton"
            active
            :title="{ width: 'full' }"
            :paragraph="{ rows: 1, width: ['100%'] }"
          />
        </a-col>
      </a-row>
      <a-row :gutter="[18, 18]" v-else>
        <a-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          v-for="(article, index) in impactSharedArticles"
          :key="index"
        >
          <impact-acticle-card
            :id="article.impact_story.id"
            :title="article.impact_story.title"
            :description="article.impact_story.description"
            :publishedDate="article.impact_story.created_at"
            :authorName="article.impact_story.author.profile.full_name"
            :authorId="article.impact_story.author.id"
            :authorProfilePic="
              article.impact_story.author.profile.profile_picture
            "
            :media="article.impact_story.media"
            :userReaction="article.impact_story.user_reaction"
            :userReactionRepresentation="
              article.impact_story.users_reaction_representation
            "
            :emails="article.impact_story.emails"
            :collabrators="article.impact_story.invitees"
            :visibleBtnMenu="true"
            @visible-drawer="showDrawer(article.impact_story.id)"
          ></impact-acticle-card>
        </a-col>
      </a-row>
    </div>
    <the-pagination
      v-if="impactSharedArticlesPagination"
      :total="totalImpactSharedArticles"
      :itemsPerPage="6"
      @change-page="changeImpactSharedArticles($event)"
    ></the-pagination>
  </div>
  <story-drawer
    v-if="articleId"
    :show-drawer="showStoryDrawer"
    @close-drawer="closeDrawer"
    :storyId="articleId"
  ></story-drawer>
</template>

<script>
import ThePagination from "../../BaseComponents/ThePagination.vue";
import ImpactActicleCard from "../../EngageComponents/ImpactActicleCard.vue";
import storyDrawer from "../../EngageComponents/StoryDarwer.vue";

export default {
  components: {
    ThePagination,
    ImpactActicleCard,
    storyDrawer,
  },
  data() {
    return {
      searchingImpactSharedArticle: false,
      articleId: null,
      showStoryDrawer: true,
    };
  },
  computed: {
    impactSharedArticles() {
      return this.$store.getters["impact/getSharedImpactArticle"].results;
    },
    impactSharedArticlesPagination() {
      return (
        this.$store.getters["impact/getImpactSharedArticleNextUrl"] ||
        this.$store.getters["impact/getImpactSharedArticlePreviousUrl"]
      );
    },
    totalImpactSharedArticles() {
      return this.$store.getters["impact/getImpactSharedTotalArticles"];
    },
  },
  mounted() {
    this.getImpactSharedArticles();
  },
  methods: {
    async getImpactSharedArticles() {
      this.searchingImpactSharedArticle = true;
      try {
        const payload = {
          user_id: this.$route.params.id,
          page: 1,
          page_size: 6,
        };
        await this.$store.dispatch(
          "impact/fetchUserImapctShareArticles",
          payload
        );
      } catch (err) {
        console.log(err);
      }
      this.searchingImpactSharedArticle = false;
    },
    async changeImpactSharedArticles(page) {
      this.searchingImpactSharedArticle = true;
      const payload = {
        user_id: this.$route.params.id,
        page: page,
        page_size: 6,
      };
      await this.$store.dispatch(
        "impact/fetchUserImapctShareArticles",
        payload
      );
      this.searchingImpactSharedArticle = false;
    },
    showDrawer(value) {
      this.articleId = value;
      this.showStoryDrawer = true;
    },
    closeDrawer() {
      this.showStoryDrawer = false;
    },
  },
};
</script>


<style lang="scss">
.shared-articles-collection {
  &__message-results-not-found {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    p {
      font-size: 1.6rem;
      font-family: $font-primary-medium;
    }
  }
  &__article-wrapper {
    background-color: $color-white;
    margin-left: 2rem;
    position: relative;
    padding: 3rem;
    @include respond(tab-port) {
      margin-left: 0;
    }
    @include respond(phone-large) {
      margin-left: 0;
    }
    @include respond(phone-x-small) {
      margin-left: 0;
    }
    .the-story-card {
      background-color: #f6f8ff;
    }
    .impact-article-card-skeleton {
      margin-bottom: 3rem;
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 300px;
          margin-top: 0;
          border-radius: 0;
        }
      }
    }
  }
}
</style>