<template>
  <div class="article-slider-container">
    <div v-if="totalSavedArticles === 0" class="article-slider-container__no-saved-articles-message">
      <h1>No saved articles found.</h1>
    </div>
    <div v-else class="article-slider-container__article-slider-section">
      <button
        class="slider-btn slider-btn--previous"
        :class="{'disable': savedArticleData.length==3}"
        @click="slideArticle(-1)"
        ref="previous"
        id="previousBtn"
        title="previousBtn"
      >
        <span class="icon-forward"></span>
      </button>
      <button
        class="slider-btn slider-btn--next"
        :class="{'disable': savedArticleData.length==3}"
        @click="slideArticle(1)"
        ref="next"
        id="nextBtn"
        title="nextBtn"
      >
        <span class="icon-forward"></span>
      </button>
      <a-row class="article-slider-container__article-slider-section--article-slider" type="flex" justify="space-between" :gutter="32">
          <div class="slide-box">
              <div class="container" ref="slider">
                <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8"
                v-for="(articles, index) in savedArticleData.save_article" :key="index">
                <article-card
                  :title="articles.article_data.title"
                  :author-name="articles.article_data.authors"
                  :text="articles.article_data.description"
                  :image="articles.article_data.image"
                  :source="articles.article_data.article_url.source"
                  :url="articles.article_data.article_url.url"
                  :id="articles.article_data.id"
                  :date="articles.article_data.pub_date"
                  :ratings="articles.article_data.rating"
                  :aggregatedRating="articles.article_data.aggregated_rating"
                  :showSaveBtn="showSavedArticlesButton"
                  :showUnsaveBtn="showUnsaveBtn"
                  :folderId="folderId"
                  :savedArticleId="articles.id"
                  :userReaction="articles.article_data.user_reaction"
                  :totalArticleReactions="articles.article_data.article_reactions"
                  :userReactionRepresentation="articles.article_data.users_reaction_representation"
                ></article-card>
                </a-col>
              </div>
          </div>
      </a-row>
    </div>
    <div class="article-slider-container__comment-section">
        <h2 class="article-slider-container__comment-section--heading">Comments:</h2>
        <folder-comments
          v-for="(comment, index) in comments"
          :key="index"
          :profilePicture="comment.profile_picture"
          :fullName="comment.full_name"
          :comment="comment.comment"
          :commentsReplies="comment.child"
          :storyId="comment.folder"
          :parentId="comment.id"
          :displayPicture="userInformation.profile_picture"
          :time="comment.human_time"
          :commentBy="comment.created_by"
          :commentFor="'saved-folder'"
        ></folder-comments>
        <div class="article-slider-container__comment-section--comment-input">
          <div class="user-image">
            <img :src="userInformation.profile_picture" alt="User image" />
          </div>
          <div class="send-comment">
            <textarea
              id=""
              rows="2"
              placeholder="Write a comment..."
              v-model="addComment"
            ></textarea>
            <the-loader v-if="loading" class="comment-textarea-loader"></the-loader>
            <button class="send-btn" :disabled="loading" @click="createComment()" id="sendBtn" title="sendBtn">
              <i class="icon icon-send"></i>
            </button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import ArticleCard from './ArticleCard.vue';
import FolderComments from "../BaseComponents/commentsSection/Comments.vue";
import TheLoader from "../BaseComponents/TheLoader.vue"; 
export default {
  components:{
    ArticleCard,
    FolderComments,
    TheLoader
  },
  props: {
    no: Number,
    folderId: String,
    saveArticles: Object,
    showUnsaveBtn: Boolean,
    totalArticles: Number
  }, 
  computed: {
    savedArticleData(){
      const articleData = this.saveArticles.filter(article => article.id === this.folderId )
      if(articleData.length > 0)
        return articleData[0] 
      return []
    },
    userInformation(){
      return this.$store.getters["profile/userInformation"].profile;
    },
    comments() {
      return this.$store.getters["profile/getSavedFoldersComments"];
    },

  },
  data() {
    return {
      showSavedArticlesButton: false,
      colored: "colored",
      slideNumber: 0,
      colunns: 24/this.no,
      totalSavedArticles: this.totalArticles,
      addComment: "",
      loading: false,
    };
  },
  mounted(){
    if(this.savedArticleData && this.savedArticleData.save_article && this.savedArticleData.save_article.length > 0){
    this.slide = this.$refs['slider'];
    this.$refs['previous'].disabled = true;
    this.$refs['previous'].classList.add('disable');
    this.$refs["slider"].style.transition = `1s`;
    if(this.savedArticleData.save_article.length<=3){
      this.$refs['next'].disabled = true;
      this.$refs['next'].classList.add('disable');
    }
    }
    this.emitter.on("savedArticleDeleted", (deletedArticleId) => {
      const savedArticleData = this.saveArticles.filter(article => article.id === this.folderId )
      const updatedArticle = savedArticleData[0].save_article.filter(article => article.id !== deletedArticleId);
      this.savedArticleData.save_article = updatedArticle;
      this.totalSavedArticles--;
    });
  },
  methods: {
    slideArticle(number) {
      this.slideNumber += number;
      this.$refs['previous'].disabled = false;
      this.$refs['next'].disabled = false;
      this.$refs['previous'].classList.remove('disable');
      this.$refs['next'].classList.remove('disable');
      this.$refs["slider"].style.transform = `translate(${
        this.slideNumber * -this.slidingWidth
        }%, 0)`;
      if(window.innerWidth<576){
        this.showArticle = 1;
        this.$refs["slider"].style.transform = `translate(${
        this.slideNumber * -100
        }%, 0)`;
        this.toggleClass();
      }
      else{
        this.showArticle = this.no;
        this.$refs["slider"].style.transform = `translate(${
        this.slideNumber * -(100/this.no)
        }%, 0)`;
        this.toggleClass();
      }
    },
    toggleClass() {
      if(this.slideNumber === this.savedArticleData.save_article.length-this.showArticle){
        this.$refs['next'].disabled = true;
        this.$refs['next'].classList.add('disable');
      }
      else if (this.slideNumber===0) {
        this.$refs['previous'].disabled = true;
        this.$refs['previous'].classList.add('disable');
      }
    },
    async createComment() {
      try {
        this.loading = true;
        const payload = {
          comment: this.addComment,
          parent: null,
          folder_id: this.folderId,
        };
        const response = await this.$store.dispatch(
          "profile/createSaveFolderComment",
          payload
        );
        if (response.status === 201) {
          this.addComment = "";
          this.comments.push(response.data)
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@include reset-css;
.article-slider-container {
  &__no-saved-articles-message {
    text-align: center;
    min-height: 10rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    opacity: 0.7;
    h1 {
      font-size: 1.6rem;
      font-family: $font-primary-bold;
    }
  }
  &__article-slider-section {
    position: relative;
    .slider-btn {
      position: absolute;
      top: 35%;
      width: 4.1rem;
      height: 4.1rem;
      background-color: $color-primary;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-white;
      cursor: pointer;
      border: none;
      &--previous {
        transform: rotate(180deg) translate(-50%, 50%);
        
      }
      &--next {
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
    .disable {
      background-color:  	#6495ED;
      cursor: not-allowed;
    }
    &--article-slider {
      display: flex;
      flex-direction: row;
      padding: 0 3rem;
      margin-top: 3rem;
      margin-left: 0 !important;
      margin-right: 0 !important;
      .slide-box{
        width: 150%;
        overflow: hidden;
        .container {
          display: flex;
          .ant-collapse {
            .ant-collapse-arrow {
              right: 0 !important;
            }
          }
          .article-card-new {
            &__body {
              .ant-collapse {
                .ant-collapse-item {
                  background-color: transparent;
                  margin-bottom: 0 !important;
                  .ant-collapse-header {
                    width: fit-content;
                  }
                  .ant-collapse-arrow {
                    right: -0.6rem !important;
                    font-size: 1.4rem !important;
                  }
                  .ant-collapse-content {
                    border: none;
                    .ant-collapse-content-box {
                      padding: 0 1.6rem 2rem !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }
  
  &__comment-section {
    border-top: 0.2rem solid rgba(128, 128, 128, 0.158);
    padding-bottom: 1rem;
    margin-top: 3rem;
    &--heading{
      margin: 3rem 0 1rem;
      font-size: 1.8rem;
      font-family: $font-primary-bold;
    }
    &--comment-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1rem 0;
      .user-image {
        width: 3rem;
        height: auto;
        border-radius: 100%;
        margin-right: 0.6rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
      .send-comment {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(238, 237, 237, 0.63) !important;
        padding: 1rem 1rem;
        border-radius: 0.5rem;
        textarea {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 1.6rem;
          font-family: $font-primary;
          line-height: 1.8rem;
          resize: none;
          &::placeholder {
            opacity: 0.7;
          }
        }
        .send-btn {
          border: none;
          height: fit-content;
          width: fit-content;
          line-height: normal;
          margin-left: 1rem;
          cursor: pointer;
          background-color: transparent;
          .icon {
            color: $color-primary;
            font-size: 2rem;
          }
        }
      }

    }
     .comment-container {
      .comment {
        &__comment-details {
          .user-details {
            background-color: rgba(238, 237, 237, 0.63);
          }
        }

        .send-comment {
          background-color: rgba(238, 237, 237, 0.63);
        }

        .reply-comment {
          &__comment-details {
            .user-details {
              background-color: rgba(238, 237, 237, 0.63);
            }
          }
        }

        .edit-comment {
          &__container {
            .btn-textarea {
              background-color: rgba(238, 237, 237, 0.63);
            }
          }
        }
      }
    }

  }
}


</style>