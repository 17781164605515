<template>
  <div class="share-collection">
    <div class="share-collection__articles">
      <a-collapse
        v-model="activeKey"
        :activeKey="queriedFolder"
        :expandIcon="expandIcon"
        :expand-icon-position="'right'"
        @change="callback"
      >
        <h1 v-if="artilcesFolders == 0 && !searchingFolders" class="no-saved-folder-message">
          No Joined Folders Found
        </h1>
       <a-row :gutter="18" v-if="searchingFolders">
          <a-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            v-for="index in 4"
            :key="index"
          >
            <a-skeleton
              class="search-folder-skeleton"
              active
              :title="{ width: 'full' }"
              :paragraph="{ rows: 1, width: ['full'] }"
            />
          </a-col>
        </a-row>
        <a-collapse-panel
          v-for="(folders) in artilcesFolders"
          :key="folders.id"
          :header="folders.folder_name"
          @click="getSavedArticle(folders.id)"
        >
        <a-row :gutter="18" v-if="searchingArticles">
          <a-col
            :xs="24"
            :sm="12" 
            :md="12" 
            :lg="8" 
            :xl="8"
            v-for="index in 3"
            :key="index"
          >
            <a-skeleton
              class="article-card-skeleton"
              active
              :title="{ width: 'full' }"
              :paragraph="{ rows: 3, width: ['full'] }"
            />
          </a-col>
        </a-row>
          <article-slider 
          v-else 
          :no="3"
          :folderId="folders.id"
          :showUnsaveBtn="false"
          :saveArticles="savedArticleData"
          :totalArticles="folders.total_save_articles"
          ></article-slider>
          <template v-slot:extra>
             <a-tooltip>
              <template #title>Leave folder</template>
              <button @click.stop="leaveJoinedArticleFolder(folders.id)" class="leave-btn">
                <img src="@/assets/icons/exit-icon.png" />
              </button>
            </a-tooltip>
            <a-tooltip>
              <template #title>{{ folders.user.full_name }}</template>
              <div class="user-details">
                <img :src="folders.user.profile_picture" />
              </div>
            </a-tooltip>
            <a-tooltip>
              <template v-if="folders.is_subscribed" #title>Turn off notifications</template>
              <template v-else #title>Turn on notifications</template>
               <div class="notification-icons" @click.stop="toggleNotifications(folders)" >
                <img src="@/assets/icons/notification-on.png" v-if="folders.is_subscribed" />
                <img src="@/assets/icons/notification-off.png" v-if="!folders.is_subscribed" />
              </div>
            </a-tooltip>
            <p class="total-articles">
              Total saved articles: {{ folders.total_save_articles }}
            </p>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
  <message-toaster 
  :visible="showMessageModal"
  :message="message"
  @handle-close="showMessageModal = false"
  >
  </message-toaster>
  <delete-modal
    :visible="visibleFolderLeaveModal"
    @handle-close="visibleFolderLeaveModal = false"
    title="Are you sure you want to leave this folder?"
    confirmText="Yes, Delete"
    cancelText="No, Don't Delete"
    :loading="loader"
    @delete-record="leaveJoinedFolder"
  ></delete-modal>
</template>

<script>
import ArticleSlider from "../../BaseComponents/ArticleSlider.vue";
import MessageToaster from  "../../BaseComponents/MessageToaster.vue";
import DeleteModal from "../../BaseComponents/DeleteModal.vue";

export default {
  components: {
    ArticleSlider,
    MessageToaster,
    DeleteModal
  },
  data() {
    return {
      activeKey: ["1"],
      searchingFolders: false,
      colored: "colored",
      loader: false,
      searchingArticles: false,
      articleData: [],
      folderId: "",
      notificationStatus: "",
      showMessageModal: false,
      message: {},
      visibleFolderLeaveModal: false,
      queriedFolder:[]
    };
  },
  computed: {
    artilcesFolders: {
      get() {
        return this.$store.getters["article/getJoinedArticleFolders"];
      },
      set(val) {
        this.$store.commit("article/setJoinedSaveArticleFolders", val);
      },
    },
    savedArticleData() { 
      return this.articleData;
    },
  },
  mounted() {
    this.getArticlesFolders();
  },
  methods: {
    expandIcon(props) {
      let icon;
      if (props.isActive) {
        this.getFolderComments()
        icon = <i class="icon icon-forward down"></i>;
        return (
          <a class="expand-row-icon">
            <span class="expand-icon-text">Collapse</span>
            {icon}
          </a>
        );
      } else {
        icon = <i class="icon icon-forward up"></i>;
        return (
          <a class="expand-row-icon">
            <span class="expand-icon-text">Expand</span>
            {icon}
          </a>
        );
      }
    },
    async getArticlesFolders() {
      this.searchingFolders = true;
      try {
        await this.$store.dispatch("article/fetchJoinedSaveArticleFolder");
      } catch (err) {
        console.log(err);
      }
      this.searchingFolders = false;
    },
    async getSavedArticle(folderId){
      this.folderId = folderId;
      try {
        const payload = {
          folder_id: folderId
        }

        let folderExists = -1
        // fetch the article index
        folderExists = this.articleData.findIndex(article => article.id === folderId)

        //API calls were being made on collapse down and up in order to eliminate the problem of excess API calls 
        // we are first finding the index, then if the index  > -1 we don't make the api call as the saved articles already exists 
        if (folderExists < 0) {
          this.searchingArticles = true;
          this.$store.dispatch("article/fetchSavedArticlesList", payload).then(response => {
            if (response.status === 200) {
                this.articleData.push(response.data);
                this.searchingArticles = false;
            }
            
          }).catch((err) => { 
            this.showMessageModal = true;
            this.message = {
              title: 'Folder does not exist',
              type: "failure",
            };
          })
        }
      } catch (err) {
        console.log(err);

      }
    },
    async getFolderComments() {
      if(this.folderId){
      try {
      await this.$store.dispatch("profile/fetchSavedFolderComments", this.folderId);
      } catch (err) {
        console.log(err);
      }
      }
    },
    async toggleNotifications(folder) {
      try {
        const action = folder.is_subscribed 
        ? "offNotificationsSavedFolder" : "onNotificationsSavedFolder";
        const response = await this.$store.dispatch(`profile/${action}`, folder.id);
        if(response.status === 200){
          folder.is_subscribed = !folder.is_subscribed;
          this.showMessageModal = true;
          this.message = {
            title: response.data.message,
            type: "success",
          };
        }
      } catch(err){
        console.log(err);
        
      }
    },
    leaveJoinedArticleFolder(id) {
      this.folderId = id;
      this.visibleFolderLeaveModal = true;
    },
    async leaveJoinedFolder(){
      try {
        this.loader = true;
        const response = await this.$store.dispatch(
          "article/deleteJoinedArticleFolder",
          this.folderId
        );
        if (response.status === 200) {
          const updatedList = this.artilcesFolders.filter((val) => {
            return val.id !== this.folderId;
          });
          this.artilcesFolders = updatedList;
          this.visibleFolderLeaveModal = false;
          this.showMessageModal = true;
          this.message = {
            title: response.data.message,
            type: "success",
          };
        }
      } catch (err) {
        console.log(err);
      }
      this.loader = false;
    },
    callback(key) { 
        this.queriedFolder = key
      }
  },
  watch: {
    $route: {
      immediate: true,
      async handler(to) { 
        if (to.query.id) { 
           await this.getSavedArticle(to.query.id)
          this.queriedFolder= to.query.id
        }
      }
    }
   }
};
</script>

<style lang="scss">
.share-collection {
  padding: 3.5rem 3.5rem 2.5rem 3.5rem;
  //override ant-collapse styles
  .ant-collapse {
    border: none;
    background-color: unset;
    .ant-collapse-item {
      border: none;
      background-color: $color-light-grey-2;
      margin-bottom: 2.5rem;
      .ant-collapse-header {
        font-size: 2rem;
        font-family: $font-secondary-bold;
        padding: 1.8rem 0 4rem 6.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .ant-collapse-arrow {
          display: flex !important;
          align-items: center !important;
          right: 1.8rem;
          .expand-icon-text {
            font-size: 1.4rem;
            font-family: $font-secondary-bold;
            display: none;
          }
          .icon {
            margin-left: 0.7rem;
            font-size: 1.6rem;
          }
          .up {
            transform: rotate(90deg);
          }
          .down {
            transform: rotate(-90deg);
          }
          .icon-expand-arrow {
            font-size: 2.8rem;
          }
        }
        .ant-collapse-extra {
          .total-articles {
            position: absolute;
            top: 4.4rem;
            left: 6.5rem;
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            margin-right: 1.5rem;
            opacity: 0.8;
          }
          .notification-icons {
            width: 2.5rem;
            height: 2.5rem;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            right: 5.5rem;
            opacity: 0.8;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            img {
              width: 2rem;
              height: auto;
            }
          }
          .user-details {
            width: 3.6rem;
            height: 3.6rem;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 1.6rem;
            opacity: 0.8;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            img {
              width: 100%;
              height: auto;
              border-radius: 100%;
              background-color: rgba(128, 128, 128, 0.363);
              object-fit: contain;
            }
          }
          .leave-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            right: 10rem;
            opacity: 0.8;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            border: none;
            outline: none;
            cursor: pointer;
            width: 1.8rem;
            height: auto;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .ant-collapse-content {
        border: none;
        .ant-collapse-content-box {
          padding: 0 !important;
        }
      }
    }
    .no-saved-folder-message {
      text-align: center;
      font-size: 1.6rem;
      font-family: $font-primary-bold;
      min-height: 16rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .article-card-skeleton {
      margin: 2rem 0;
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 300px;
          margin-top: 0;
          border-radius: 0;
        }
      }
    }
    .search-folder-skeleton {
      margin-bottom: 3rem;
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 8rem;
          margin-top: 0;
          border-radius: 0;
        }
        .ant-skeleton-paragraph {
          display: none !important;
        }
      }
    }
  }
}

</style>