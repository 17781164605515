<template>
  <div class="my-articles-collection">
    <div
      v-if="highlightedCurrentEvents == 0 && !searchingArticles"
      class="my-articles-collection__message-results-not-found"
    >
      <p>No Highlighted Current Events Found</p>
    </div>
    <div v-else class="my-articles-collection__article-wrapper">
      <a-row :gutter="[18, 18]" v-if="searchingArticles">
        <a-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          v-for="index in 6"
          :key="index"
        >
          <a-skeleton
            class="impact-article-card-skeleton"
            active
            :title="{ width: 'full' }"
            :paragraph="{ rows: 1, width: ['100%'] }"
          />
        </a-col>
      </a-row>
      <a-row :gutter="[18, 18]" v-if="!searchingArticles">
        <a-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          v-for="(articles, index) in highlightedCurrentEvents"
          :key="index"
        >
          <impact-acticle-card
            :id="articles.id"
            :title="articles.title"
            :description="articles.description"
            :publishedDate="articles.created_at"
            :authorName="articles.author.profile.full_name"
            :authorProfilePic="articles.author.profile.profile_picture"
            :media="articles.media"
            :visibleBtnMenu="false"
            :emails="articles.emails"
            :collabrators="articles.invitees"
            @visible-drawer="showDrawer(articles.id)"
            :currentPage="currentPage"
          ></impact-acticle-card>
        </a-col>
      </a-row>
    </div>
    <the-pagination
      v-if="impactArticlesPagination"
      :total="totalHighlightedCurrentEvents"
      :itemsPerPage="6"
      @change-page="changePageImpactArticles($event)"
    ></the-pagination>
  </div>
  <story-drawer
    v-if="articleId"
    :show-drawer="showStoryDrawer"
    @close-drawer="closeDrawer"
    :storyId="articleId"
  ></story-drawer>
</template>

<script>
import ThePagination from "../../BaseComponents/ThePagination.vue";
import ImpactActicleCard from "../../EngageComponents/ImpactActicleCard.vue";
import storyDrawer from "../../EngageComponents/StoryDarwer.vue";

export default {
  components: {
    ThePagination,
    ImpactActicleCard,
    storyDrawer,
  },
  data() {
    return {
      searchingArticles: false,
      currentPage: 0,
      articleId: null,
      showStoryDrawer: true,
    };
  },
  computed: {
    highlightedCurrentEvents() {
      return this.$store.getters["impact/getImpactArticles"].results;
    },
    totalHighlightedCurrentEvents() {
      return this.$store.getters["impact/getImpactTotalArticles"];
    },
    impactArticlesPagination() {
      return (
        this.$store.getters["impact/getImpactArticleNextUrl"] ||
        this.$store.getters["impact/getImpactArticlePreviousUrl"]
      );
    },
  },
  mounted() {
    this.getHighlightedCurrentEvents();
  },
  methods: {
    async getHighlightedCurrentEvents() {
      try {
        const payload = {
          query: "",
          offset: 0,
          limit: 9,
          author: this.$route.params.id,
        };
        this.searchingArticles = true;
        const response = await this.$store.dispatch(
          "impact/fetchImpactArticle",
          payload
        );
        if (response.status == 200) {
          this.searchingArticles = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async changePageImpactArticles(page) {
      this.searchingArticles = true;
      this.currentPage = page;
      const payload = {
        query: "",
        offset: (page - 1) * 9,
        limit: 9,
        author: this.$route.params.id,
      };
      await this.$store.dispatch("impact/fetchImpactArticle", payload);
      this.searchingArticles = false;
    },
    showDrawer(value) {
      this.articleId = value;
      this.showStoryDrawer = true;
    },
    closeDrawer() {
      this.showStoryDrawer = false;
    },
  },
};
</script>


<style lang="scss">
.my-articles-collection {
  &__message-results-not-found {
    width: 100%;
    height: 21.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    p {
      font-size: 1.6rem;
      font-family: $font-primary-medium;
    }
  }
  &__article-wrapper {
    background-color: $color-white;
    margin-left: 2rem;
    position: relative;
    padding: 3rem;
    @include respond(tab-port) {
      margin-left: 0;
    }
    @include respond(phone-large) {
      margin-left: 0;
    }
    @include respond(phone-x-small) {
      margin-left: 0;
    }
    .the-story-card {
      background-color: #f6f8ff;
    }
    .impact-article-card-skeleton {
      margin-bottom: 3rem;
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 300px;
          margin-top: 0;
          border-radius: 0;
        }
      }
    }
  }
}
</style>