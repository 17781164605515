<template>
    <div class="portfolio-impact-article">
      <div class="portfolio-impact-article__header">
        <Draggable v-model="items" class="draggable-container">
          <template v-slot:item="{item}">
            <a
              class="collections"
              :class="{ active: isActive(item.id) }"
              @click="chageTab(item.id)"
            >
              <span>{{ item.name }}</span></a
            >
          </template>
      </Draggable>
      </div>
      <div v-if="selectedTab === 'my-collection'">
        <my-highlighted-current-events></my-highlighted-current-events>
      </div>
      <div v-if="selectedTab === 'shared-collection'">
        <shared-highlighted-current-events></shared-highlighted-current-events>
      </div>
    </div>
  </template>
  
  
  <script>
  import MyHighlightedCurrentEvents from './MyHighlightedCurrentEvents.vue';
  import SharedHighlightedCurrentEvents from './SharedHighlightedCurrentEvents.vue';
  import Draggable from "vue3-draggable";

  export default {
    components: {
        MyHighlightedCurrentEvents,
        SharedHighlightedCurrentEvents,
        Draggable
    },
    data() {
      return {
        selectedTab: "my-collection",
        addToCollectionModalVisible: false,
        items: [
        { id: 'my-collection' , name: 'My Articles'},
        { id: 'shared-collection' , name: 'Shared Articles'},
        ]
      };
    },
    methods: {
      chageTab(tab) {
        this.selectedTab = tab;
      },
      isActive(tab) {
        return this.selectedTab == tab;
      },
    },
    watch: {
      $route: {
        immediate: true,
        handler(to) {
          if (to.query.article_tab) { 
            this.selectedTab = to.query.article_tab
          }
        }
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .portfolio-impact-article {
    background-color: $color-white;
    &__header {
      border-bottom: 1px solid rgba($color-black, 0.2);
      padding: 3rem;
      display: flex;
      .draggable-container {
      display: flex;
      align-items: center;
      .collections {
        font-size: 1.6rem;
        color: $color-black;
        position: relative;
        margin-right: 2.2rem;
        cursor: grab;
        span {
          opacity: 50%;
        }
      }
      .active {
        font-family: $font-secondary-bold;
        span {
          opacity: 1;
        }
        &::after {
          content: "";
          background-color: $color-primary;
          position: absolute;
          top: 3rem;
          left: 0;
          width: 2.5rem;
          height: 0.3rem;
        }
      }
    }
      .active {
        &::after {
          content: "";
          background-color: $color-primary;
          position: absolute;
          top: 3rem;
          left: 0;
          width: 2.5rem;
          height: 0.3rem;
        }
      }
    }
  }
  </style>