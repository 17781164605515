<template>
  <div class="saved-article">
    <div class="saved-article__header">
      <Draggable v-model="items" class="draggable-container">
          <template v-slot:item="{item}">
            <a
              class="collections"
              :class="{ active: isActive(item.id) }"
              @click="chageTab(item.id)"
            >
              <span>{{ item.name }}</span></a
            >
          </template>
      </Draggable>
    </div>
    <div v-if="selectedTab === 'my-collection'">
      <my-collection></my-collection>
    </div>
    <div v-if="selectedTab === 'shared-collection'">
      <shared-collection></shared-collection>
    </div>
  </div>
</template>


<script>
import MyCollection from "./MyCollection.vue";
import SharedCollection from "./ShareCollection.vue";
import Draggable from "vue3-draggable";

export default {
  components: {
    MyCollection,
    SharedCollection,
    Draggable
  },
  data() {
    return {
      selectedTab: "my-collection",
      addToCollectionModalVisible: false,
      items: [
        { id: 'my-collection' , name: 'My Collection'},
        { id: 'shared-collection' , name: 'Shared Collections'},
    ]
    };
  },
  methods: {
    chageTab(tab) {
      this.selectedTab = tab;
    },
    isActive(tab) {
      return this.selectedTab == tab;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.query.article_tab) { 
          this.selectedTab = to.query.article_tab
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.saved-article {
  background-color: $color-white;
  &__header {
    border-bottom: 1px solid rgba($color-black, 0.2);
    padding: 3rem;
    padding-top: 0.5rem;
    display: flex;
    .draggable-container {
      display: flex;
      align-items: center;
      .collections {
        font-size: 1.6rem;
        color: $color-black;
        position: relative;
        margin-right: 2.2rem;
        cursor: grab;
        span {
          opacity: 50%;
        }
      }
      .active {
        font-family: $font-secondary-bold;
        span {
          opacity: 1;
        }
        &::after {
          content: "";
          background-color: $color-primary;
          position: absolute;
          top: 3rem;
          left: 0;
          width: 2.5rem;
          height: 0.3rem;
        }
      }
    }
  }
}
</style>