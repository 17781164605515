<template>
  <div class="other-information-section">
    <ProfileSectionManager />
  </div>
</template>
<script>
import ProfileSectionManager from "./ProfileSectionManager.vue";
export default {
  components: {
    ProfileSectionManager,
  },
};
</script>
<style lang="scss">
.other-information-section {
  border: 1px solid $color-dark-grey-5;
  border-radius: 2rem;
  margin-top: 2rem;
}
</style>
