<template>
  <div class="events-section">
    <div class="events-section__header">
      <h2 class="events-section__header--title">Events</h2>
      <button
        class="events-section__header--btn"
        v-if="isLoggedInUser"
        @click="openCreateEventModal()"
        id="addEventBtn"
        title="addEventBtn"
      >
        <i class="icon icon-add_plus"></i>
      </button>
    </div>
    <div v-if="loadingEvents" class="events-section__body">
      <a-row :gutter="[18, 18]">
        <a-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6"
            v-for="eventsCardSkeleton in 4" 
            :key="eventsCardSkeleton"
        >
            <article-card-skeleton></article-card-skeleton>
        </a-col>
      </a-row>
    </div>
    <div v-else class="events-section__body">
      <a-row :gutter="[18, 18]">
        <a-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="6"
          :xl="6"
          v-for="item,index in events"
          :key="index"
        >
          <div class="create-event-card">
            <div
              class="create-event-card__header"
              :style="{
                backgroundImage: `url(${item?.files[0]?.file})`,
              }"
            >
              <a-dropdown
                @click.stop
                :trigger="['click']"
                placement="bottomRight"
              >
                <a class="create-event-card__header--dropdown">
                  <i class="icon icon-vertical_three-dots"></i>
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="1">
                      <a>Copy link</a>
                    </a-menu-item>
                    <a-menu-item key="2">
                      <a class="remove-btn">
                        Report this event
                      </a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
            <div class="create-event-card__body">
              <h2 class="create-event-card__body--title">
                {{ item.title }}
              </h2>
              <p class="create-event-card__body--event-by">
                Event by <a>{{ item.user.full_name }}</a>
              </p>
              <div class="create-event-card__body--time">
                <img src="@/assets/icons/calendar-icon.png" />
                <p>{{ formatDateTime(item.schedule.lower)}} - {{ formatDateTime(item.schedule.upper)}} ({{ item.timezone }})</p>
              </div>
              <div class="create-event-card__body--event-type">
                <img src="@/assets/icons/video-camera-icon.png" />
                <p>{{ item.event_type === 'online' ? 'Online' : 'In person' }}</p>
              </div>
              <!-- <div class="create-event-card__body--attendees">
                <p>36,474 attendees</p>
              </div> -->
              <div class="create-event-card__body--buttons">
                <button class="btn" @click="openEventViewModal(item)">
                  View
                </button>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <p
      v-if="!loadingEvents && events.length == 0"
      class="events-section__message"
    >
      No events listed
    </p>
    <the-pagination
      v-if="eventsPagination"
      :total="getEventsCount"
      :itemsPerPage="8"
      @change-page="changePageEvents($event)"
    >
    </the-pagination> 
  </div>

  <CreateEventModal
    :visible="visibleCreateEventModal"
    @closeModal="closeCreateEventModal"
    :singleEvent="event"
  />
  <EventViewModal 
    :visible="visibleEventViewModal"
    @closeModal="closeEventViewModal"
  />
</template>
<script>
import CreateEventModal from "./CreateEventModal.vue";
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ThePagination from '../BaseComponents/ThePagination.vue'
import ArticleCardSkeleton from '../BaseComponents/ArticleCardSkeleton.vue';
import EventViewModal from './EventViewModal.vue';
export default {
  data() {
    return {
      visibleCreateEventModal: false,
      visibleEventViewModal:false,
      loadingEvents:false,
      event:null
    };
  },
  components: {
    CreateEventModal,
    ArticleCardSkeleton,
    ThePagination,
    EventViewModal
  },
  computed: {
     ...mapGetters({
      events: "profile/getEvents",
    }),
    eventsPagination() {
      return (
        this.$store.getters["profile/getEventsNext"] ||
        this.$store.getters["profile/getEventsPrevious"]
      );
    },
    totalEvents() {
      return this.$store.getters["profile/getTotalEvents"];
    },
    getEventsCount(){
      return this.$store.getters["profile/getEventsCount"];
    },
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
    isEventsLoading() {
      return this.$store.getters["profile/getEventsSkeletonLoader"];
    },
  },
  async mounted() {
    await this.getAllEvents();
  },  
  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm A');
    },
    openCreateEventModal() {
      this.visibleCreateEventModal = true;
    },
    closeCreateEventModal() {
      this.visibleCreateEventModal = false;
      // this.event=null;
    },
    openEventViewModal(item) {
      this.$store.dispatch('profile/selectedEvent', item);
      this.visibleEventViewModal = true;
      // if (item.permission === "organizer") {
      //   this.event = item;
      //   this.visibleCreateEventModal = true;
      // }else{
      //    this.visibleEventViewModal = true;
      // }
    },
    closeEventViewModal() {
      this.visibleEventViewModal = false;
    },
    async getAllEvents() {
      try {
        this.loadingEvents = true;
        const payload = {
        user_id: this.$route.params.id,
        page: 1,
        page_size: 8,
      };
        await this.$store.dispatch("profile/getAllEvent", {
          user_id:this.$route.params.id,
          payload: payload
        });
      } catch (error) {
        console.log('error',error);
      } finally{
        this.loadingEvents = false;
      }
    },
    async changePageEvents(page) {
      try {
        this.loadingEvents = true;
      const payload = {
        user_id: this.$route.params.id,
        page: page,
        page_size: 8,
      };
      await this.$store.dispatch("profile/getAllEvent", {
          user_id:this.$route.params.id,
          payload: payload
        });
      } catch (error) {
        console.log('error',error);
      } finally{
        this.loadingEvents = false;
      }
    },
  },
};
</script>

<style lang="scss">
.events-section {
  padding: 0 2.6rem 3rem;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--title {
      font-size: 2rem;
      font-family: $font-primary-medium;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
    }
    &--btn {
      background-color: $color-primary;
      height: 4.2rem;
      width: 4.2rem;
      border: none;
      outline: none;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon {
        color: $color-white;
        font-size: 1.6rem;
      }
    }
  }
  &__body {
    margin-top: 2.6rem;
    overflow: hidden;
    .create-event-card {
      &__header {
        width: 100%;
        height: 22rem;
        border-start-start-radius: 1rem;
        border-start-end-radius: 1rem;
        background-position: 100%, 100%;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        background-color: rgba(223, 223, 223, 0.719);
        &--dropdown {
          background-color: #fff;
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            color: $color-black;
            font-size: 1.2rem;
          }
        }
      }
      &__body {
        padding: 1.6rem 1.4rem;
        border: 1px solid $color-dark-grey-5;
        border-top: none;
        border-end-start-radius: 1rem;
        border-end-end-radius: 1rem;
        &--title {
          font-size: 1.9rem;
          font-family: $font-primary-medium;
          margin-bottom: 1rem;
          line-height: 2.2rem;
          color: $color-black;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        &--event-by {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          font-family: $font-primary;
          margin-bottom: 0;
          line-height: 1.8rem;
          color: $color-black;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 1.2rem;
          a {
            font-family: $font-primary-medium;
            color: $color-primary;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
              opacity: 0.7;
            }
          }
        }
        &--time {
          img {
            margin-top: 0.1rem;
          }
        }
        &--time,
        &--event-type,
        &--attendees {
          display: flex;
          align-items: flex-start;
          gap: 0.5rem;
          margin-bottom: 1.2rem;
          img {
            width: 1.8rem;
            opacity: 0.7;
          }
          p {
            font-size: 1.4rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 1.8rem;
            color: $color-black;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        &--buttons {
          margin-top: 2rem;
          .btn {
            width: 100%;
            height: 4rem;
            font-size: 1.4rem;
            border: none;
            outline: none;
            background-color: $color-primary;
            color: $color-white;
            font-family: $font-primary-medium;
            border-radius: 1rem !important;
            cursor: pointer;
          }
        }
      }
    }
  }
  &__message {
    font-size: 1.6rem;
    font-family: $font-primary;
    margin-bottom: 0;
    line-height: 2.3rem;
    color: $color-black;
  }
}
</style>
